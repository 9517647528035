<template>
	<div class="wrap">
		Page two
	</div>
</template>

<style lang="scss" scoped>
.wrap {
	background: #eec;
	padding: 0.4rem;
}
</style>
